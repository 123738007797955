<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Payroll History</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-karyawan">Karyawan</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link
                    :to="{
                      name: 'DetailEmployee',
                      params: {
                        id: id_karyawan,
                      },
                    }"
                    >Payroll History</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Detail Payroll
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="card info-detail">
          <div class="row align-items-center">
            <div class="col-4 text-left">
              <div class="title-invoice">Finanta</div>
              <div class="address">
                {{ formData.alamat }}
              </div>
            </div>
            <div
              class="title-payroll col-4 text-center"
              v-if="formData.kategori == 'gaji'"
            >
              Slip Gaji
            </div>
            <div
              class="title-payroll col-4 text-center"
              v-else-if="formData.kategori == 'bonus'"
            >
              Slip Bonus
            </div>
            <div
              class="title-payroll col-4 text-center"
              v-else-if="formData.kategori == 'thr'"
            >
              Slip THR
            </div>

            <div
              class="title-payroll col-4 text-center"
              v-else-if="formData.kategori == 'man power'"
            >
              Slip Man Power
            </div>

            <div class="col-4">
              <div class="row justify-content-end">
                <div class="col-4">
                  <span class="text">Bulan </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{ formatMonth(formData.bulan) }}</span>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-4">
                  <span class="text">Tanggal </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{
                    formatDate(formData.tanggal_bayar)
                  }}</span>
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-4">
                  <span class="text">No. Referensi </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{ formData.id }}</span>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-4">
                  <span class="text">Karyawan </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{ formData.nama_karyawan }}</span>
                </div>
              </div>

              <div
                class="row justify-content-end"
                v-if="formData.kategori != 'man power'"
              >
                <div class="col-4">
                  <span class="text">Jabatan </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{ formData.nama_jabatan }}</span>
                </div>
              </div>

              <div class="row justify-content-end" v-else>
                <div class="col-4">
                  <span class="text">Project Level </span>
                </div>

                <div class="col-1">
                  <span style="width: 100px">:</span>
                </div>

                <div class="col-6">
                  <span class="value">{{ formData.nama_level }}</span>
                </div>
              </div>
              <!-- <div class="d-flex justify-content-start">
                                <span class="text">Karyawan :</span>
                                <span class="value">Max Verstappen</span>
                            </div>
                            <div class="d-flex justify-content-start">
                                <span class="text">Jabatan :</span>
                                <span class="value">UI/UX Design</span>
                            </div> -->
            </div>
          </div>
          <hr />
          <div class="row mt-3">
            <div class="col-6">
              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped"
                  id="tablePemasukan"
                >
                  <thead>
                    <tr>
                      <th style="width: 65%">Pemasukan</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in ListItem.pemasukan"
                      :key="index"
                    >
                      <td>{{ value.nama }}</td>
                      <td>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(value.nilai)
                        }}</span>
                      </td>
                    </tr>
                    <tr v-if="ListItem.pemasukan.length == 0">
                      <td colspan="2" class="text-center">
                        Tidak ada pemasukan
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped"
                  id="table-total"
                >
                  <tbody>
                    <tr>
                      <td style="width: 65%">Total Pemasukan</td>
                      <td>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(ListItem.nilai_pemasukan)
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-6" v-if="formData.kategori == 'gaji'">
              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped"
                  id="tablePotongan"
                >
                  <thead>
                    <tr>
                      <th style="width: 65%">Potongan</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in ListItem.potongan"
                      :key="index"
                    >
                      <td>{{ value.nama }}</td>
                      <td>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(value.nilai)
                        }}</span>
                      </td>
                    </tr>
                    <tr v-if="ListItem.potongan.length == 0">
                      <td colspan="2" class="text-center">
                        Tidak ada potongan
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped"
                  id="table-total"
                >
                  <tbody>
                    <tr>
                      <td style="width: 65%">Potongan</td>
                      <td>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(ListItem.nilai_potongan)
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped"
                  id="tableSubsidi"
                >
                  <thead>
                    <tr>
                      <th style="width: 65%">Subsidi</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, index) in ListItem.subsidi" :key="index">
                      <td>{{ value.nama }}</td>
                      <td>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(value.nilai)
                        }}</span>
                      </td>
                    </tr>
                    <tr v-if="ListItem.subsidi.length == 0">
                      <td colspan="2" class="text-center">Tidak ada subsidi</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped"
                  id="table-total"
                >
                  <tbody>
                    <tr>
                      <td style="width: 65%">Subisidi</td>
                      <td>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(ListItem.nilai_subsidi)
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              class="col-md-6"
              :class="{ 'offset-md-6': formData.kategori == 'gaji' }"
            >
              <div class="total">
                <div class="row pb-0">
                  <div class="col-8">
                    <div>Total Pemasukan</div>
                    <div v-if="formData.kategori == 'gaji'">Total Potongan</div>
                  </div>
                  <div class="col-4">
                    <div>
                      <span>Rp</span>
                      <span class="float-right">{{
                        formatMoney(ListItem.nilai_pemasukan)
                      }}</span>
                    </div>
                    <div v-if="formData.kategori == 'gaji'">
                      <span>Rp</span>
                      <span class="float-right">{{
                        formatMoney(ListItem.nilai_potongan)
                      }}</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="total-payroll">
                  <div class="row">
                    <div class="col-8">
                      <div>Total diterima</div>
                    </div>
                    <div class="col-4">
                      <div>
                        <span>Rp</span>
                        <span class="float-right">{{
                          formatMoney(
                            ListItem.nilai_pemasukan - ListItem.nilai_potongan
                          )
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="total mt-4">
                <div class="total-payroll">
                  <div class="row">
                    <div class="col-12">
                      <div>Terbilang :</div>
                      <div class="mt-2 font-italic">
                        {{
                          `“ ${capitlizeFirst(
                            setTerbilang(
                              ListItem.nilai_pemasukan - ListItem.nilai_potongan
                            )
                          )} rupiah ”`
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-end"></div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import moment from "moment";
import "moment/locale/id";
import { get_PayrollSlip } from "../../../actions/payroll";
import { showAlert } from "../../../helper";

// import $ from 'jquery'

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
  },

  data() {
    return {
      id_karyawan: this.$route.params.id_karyawan,
      id_payroll: this.$route.params.id_payroll,
      ListItem: {
        pemasukan: [],
        potongan: [],
        subsidi: [],
        nilai_pemasukan: 0,
        nilai_potongan: 0,
        nilai_subsidi: 0,
      },
      DataTable: "",
      selectAll: false,
      formData: {
        id: "",
        id_payroll: "",
        kategori: "gaji",
        bulan: "",
        tanggal_bayar: "",
        keterangan: "",
        akun_perkiraan: "",
        nama_karyawan: "",
        nama_divisi: "",
        nama_jabatan: "",
        alamat: "",
        nama_level: "-",
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    this.getData();
  },
  methods: {
    capitlizeFirst(str) {
      // checks for null, undefined and empty string
      if (!str) return;
      return str.match("^[a-z]")
        ? str.charAt(0).toUpperCase() + str.substring(1)
        : str;
    },
    setTerbilang(angka) {
      var bilne = [
        "",
        "satu",
        "dua",
        "tiga",
        "empat",
        "lima",
        "enam",
        "tujuh",
        "delapan",
        "sembilan",
        "sepuluh",
        "sebelas",
      ];

      if (angka < 12) {
        return bilne[angka];
      } else if (angka < 20) {
        return this.setTerbilang(angka - 10) + " belas";
      } else if (angka < 100) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 10)) +
          " puluh " +
          this.setTerbilang(parseInt(angka) % 10)
        );
      } else if (angka < 200) {
        return "seratus " + this.setTerbilang(parseInt(angka) - 100);
      } else if (angka < 1000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 100)) +
          " ratus " +
          this.setTerbilang(parseInt(angka) % 100)
        );
      } else if (angka < 2000) {
        return "seribu " + this.setTerbilang(parseInt(angka) - 1000);
      } else if (angka < 1000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000)) +
          " ribu " +
          this.setTerbilang(parseInt(angka) % 1000)
        );
      } else if (angka < 1000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000)) +
          " juta " +
          this.setTerbilang(parseInt(angka) % 1000000)
        );
      } else if (angka < 1000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000)) +
          " milyar " +
          this.setTerbilang(parseInt(angka) % 1000000000)
        );
      } else if (angka < 1000000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000000)) +
          " trilyun " +
          this.setTerbilang(parseInt(angka) % 1000000000000)
        );
      }
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatMonth(string) {
      return moment(string + "-01").format("MMMM YYYY");
    },

    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },

    getData() {
      get_PayrollSlip(
        this.id_payroll,
        (res) => {
          var { detail, data } = res;
          this.formData.id = data.id;
          this.formData.id_payroll = data.id_payroll;
          this.formData.kategori = data.kategori;
          this.formData.bulan = data.bulan;
          this.formData.tanggal_bayar = data.tanggal_bayar;
          this.formData.nama_karyawan = data.nama_karyawan;
          this.formData.nama_divisi = data.nama_divisi;
          this.formData.nama_jabatan = data.nama_jabatan;
          this.formData.alamat = data.alamat_company;
          this.formData.nama_level = data.nama_level;
          this.ListItem = detail;
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data Slip gaji tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
          });
        }
      );
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.title-invoice {
  font-weight: 700;
  font-size: 48px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.total-payroll {
  background: #d7ffea;
  border-radius: 0px 0px 4px 4px;
}

.total > .row:first-child {
  line-height: 40px;
}

.total {
  font-size: 14px;
  border: #e3e3e3 1px solid;
  border-radius: 5px;
}

.total .row {
  padding: 15px 25px;
}

#table-total td {
  background-color: #ffd7d7 !important;
  border: none;
  padding: 0px 25px;
  height: 50px;
  /* border-radius: 10px; */
}

#table-total tr td:first-child {
  border-top-left-radius: 10px;
}

#table-total tr td:last-child {
  border-top-right-radius: 10px;
}

.text {
  font-size: 14px;
}

.address {
  width: 400px;
  font-size: 14px;
}

.title-payroll {
  font-size: 37px;
  color: #263238;
  font-weight: 600;
}
</style>
